import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

import Layout from './components/Layout';
import Header from './components/Header';
import AuthPage from './components/AuthPage';

import Clinical from './Clinical/Assessment';
import PatientInput from './Clinical/PatientInput';
import EvidencePage from './Clinical/Main/EvidencePage';
import SuccessApp from './Clinical/Success';

import { useLocation } from 'react-router-dom';

import { PatientProp } from './Patients/Props';
import { UserProp } from './components/Props';
import useFirebaseUser from "./firebase/useFirebaseUser";
import TimeoutModal from './components/TimeoutModal'; // You'll need to create this component

function getEmptyPatient(): PatientProp {
  return {
    age: 0,
    dob: '',
    pregnant: 'No',
    sex: null,
    weight: null,
    current_medical_conditions: [],
    prescribed_medications: [],
    past_medications: [],
    allergies: [],
    social_hx: '',
    clinical_symptoms: [],
    relevant_labs: [],
    physical_exam_findings: [],
    additional_notes: '',
    height: null
  };
}

const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:5001';

const requestDTPBackend = async (query: any) => {
  const endpoint = `${BACKEND_ENDPOINT}/api/agents/dtp/v3`;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "query": query,
      "stream": true
    }),
  });
  const resJson = await response.json();
  return resJson;
}

export default function App() {
  const [currentApp, setCurrentApp] = useState('PatientInput');
  const [currentPatient, setCurrentPatient] = useState(getEmptyPatient());
  const [currentUser, setCurrentUser] = useState<UserProp | null>(null);
  const [showReloadPrompt, setShowReloadPrompt] = useState(false);
  const { user, isLoading, onLogin } = useFirebaseUser();
  const [currentTask, setTask] = useState("");
  const [conditionsEvidence, setConditionsEvidence] = useState({});

  useEffect(() => {
    if (user) {
      setCurrentUser({
        id: user.email || '',
        rxistName: user?.displayName || '',
        rxName: 'Asepha Pharmacy',
        rxAddress: '123 Main St, Toronto, Ontario, A1B2C3',
        rxPhone: '123-456-7890'
      });
    } else {
      setCurrentUser(null);
    }
  }, [user]);

  useEffect(() => {
    let inactivityTimer: NodeJS.Timeout;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => setShowReloadPrompt(true), 8 * 60 * 1000); // 8 minutes
    }

    resetInactivityTimer();

    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      resetInactivityTimer();
    };

    events.forEach(event => document.addEventListener(event, resetTimer));

    return () => {
      events.forEach(event => document.removeEventListener(event, resetTimer));
      clearTimeout(inactivityTimer);
    };
  }, []);

  const handleCloseReloadPrompt = () => {
    setShowReloadPrompt(false);
    window.location.reload();
  };

  const fetchDTPBackend = async () => {
    if (currentPatient.prescribed_medications.length === 0) {
      return;
    }
    const patient = {
      "age": currentPatient.age,
      "sex": currentPatient.sex,
      "weight": currentPatient.weight,
      "current_medical_conditions": currentPatient.current_medical_conditions,
      "prescribed_medications": currentPatient.prescribed_medications,
      "past_medications": currentPatient.past_medications,
      "allergies": currentPatient.allergies,
      "social_hx": currentPatient.social_hx,
      "clinical_symptoms": currentPatient.clinical_symptoms,
      "relevant_labs": currentPatient.relevant_labs,
      "physical_exam_findings": currentPatient.physical_exam_findings,
      "additional_notes": currentPatient.additional_notes,
      "height": null,
    };
    let task = await requestDTPBackend(patient);
    setTask(task);
  };

  useEffect(() => {
    fetchDTPBackend();
  }, [currentApp]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }
  
  if (!user) {
    return (
      <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <AuthPage onLogin={(authUser) => {
          // Handle successful login
          onLogin();
          if (authUser) {
            setCurrentUser({
              id: authUser.email || '',
              rxistName: authUser.displayName || '',
              rxName: 'Asepha Pharmacy',
              rxAddress: '123 Main St, Toronto, Ontario, A1B2C3',
              rxPhone: '123-456-7890'
            });
          }
        }} />
      </CssVarsProvider>
    );
  }

  function EvidencePageWrapper({ drugsEvidence, conditionsEvidence }: { drugsEvidence: Record<string, any>, conditionsEvidence: Record<string, any> }) {
    const location = useLocation();
    const data = location.state?.data || { drugs: drugsEvidence, conditions: conditionsEvidence };

    return <EvidencePage data={data} />;
  }

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Layout.Root>
        <Layout.Header>
          {currentUser && <Header currentUser={currentUser} />}
        </Layout.Header>
        <Router>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/assessment" replace />}
            />
            <Route
              path="/assessment"
              element={
                <Clinical
                  task={currentTask}
                  currentPatient={currentPatient}
                  setCurrentPatient={setCurrentPatient}
                  setCurrentApp={setCurrentApp}
                  setConditionsEvidence={setConditionsEvidence}
                  conditionsEvidence={conditionsEvidence}
                />
              }
            />
            <Route
              path="/success"
              element={
                <SuccessApp />
              }
            />
          </Routes>
        </Router>
      </Layout.Root>
    </CssVarsProvider>
  );
}
