import React, { useState, useEffect } from 'react';

import { Button } from '@mui/joy';
import { Box } from '@mui/joy';
import Typography from '@mui/joy/Typography';

import { PatientProp } from '../Patients/Props';

import { Grid } from '@mui/material';

import List from '@mui/joy/List';
import CircularProgress from '@mui/joy/CircularProgress';

import Autocomplete from '@mui/joy/Autocomplete';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Option from '@mui/joy/Option';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Select from '@mui/joy/Select';

import { useNavigate } from 'react-router-dom';

const BACKEND_ENDPOINT = 'https://dev.backend.glycogen-ai.com';

interface PatientProfileProps {
    currentPatient: Patient;
    setCurrentPatient: React.Dispatch<React.SetStateAction<Patient>>;
}

interface ExtractionOptionsProps {
    setExtractionStep: React.Dispatch<React.SetStateAction<string>>;
}

interface Patient {
    age: number;
    dob: string;
    pregnant: string;
    sex: string | null;
    weight: string | null;
    current_medical_conditions: string[];
    prescribed_medications: string[];
    past_medications: string[];
    allergies: string[];
    social_hx: string;
    clinical_symptoms: string[];
    relevant_labs: string[];
    physical_exam_findings: string[];
    additional_notes: string;
    height: number | null;
}

function getEmptyPatient(): Patient {
    return {
        age: 0,
        dob: '',
        pregnant: "No",
        sex: null,
        weight: null,
        current_medical_conditions: [],
        prescribed_medications: [],
        past_medications: [],
        allergies: [],
        social_hx: '',
        clinical_symptoms: [],
        relevant_labs: [],
        physical_exam_findings: [],
        additional_notes: '',
        height: null
    };
}

function PatientProfile({ currentPatient, setCurrentPatient }: PatientProfileProps): JSX.Element {
    const [weightUnit, setWeightUnit] = useState<string>(currentPatient?.weight ? currentPatient.weight.split(' ')[1] : 'kg');

    const calculatePatientDoB = (age: number): string => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();
        const dob = new Date(year - age, month, day);
        return dob.toISOString().split('T')[0];
    }

    const calculatePatientDoBFromDob = (date: string): string => {
        const dob = new Date(date);
        return dob.toISOString().split('T')[0];
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ mb: .5 }}>
                        <Typography fontSize={'1rem'}>Date of Birth</Typography>
                        <Input
                            size="sm"
                            type="date"
                            slotProps={{
                                input: {
                                    min: '1918-01-01',
                                    max: '2025-01-01',
                                },
                            }}
                            value={currentPatient.dob ? calculatePatientDoBFromDob(currentPatient.dob) : currentPatient.age ? calculatePatientDoB(currentPatient.age) : ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPatient({ ...currentPatient, dob: e.target.value, age: new Date().getFullYear() - new Date(e.target.value).getFullYear() })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ mb: .5 }}>
                        <Typography fontSize={'1rem'}>Sex</Typography>
                        <Autocomplete
                            size="sm"
                            freeSolo={true}
                            value={currentPatient.sex || ''}
                            placeholder="Male, Female, etc…"
                            options={[
                                'Male',
                                'Female',
                                'Other',
                            ]}
                            onChange={(_, value) => setCurrentPatient({ ...currentPatient, sex: value || '' })}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2.5 }} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ my: .5 }}>
                        <Typography fontSize={'1rem'}>Weight</Typography>
                        <Input
                            placeholder="Weight"
                            size="sm"
                            value={currentPatient.weight ? currentPatient.weight.split(' ')[0] : ''}
                            endDecorator={
                                <React.Fragment>
                                    <Divider orientation="vertical" />
                                    <Select
                                        size="sm"
                                        variant="plain"
                                        value={weightUnit}
                                        onChange={(_, value) => setWeightUnit(value as string)}
                                        slotProps={{
                                            listbox: {
                                                variant: 'outlined',
                                            },
                                        }}
                                        sx={{ mr: -1, '&:hover': { bgcolor: 'transparent' } }}
                                    >
                                        <Option value="kg">kg</Option>
                                        <Option value="lbs">lbs</Option>
                                    </Select>
                                </React.Fragment>
                            }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPatient({ ...currentPatient, weight: `${e.target.value} ${weightUnit}` })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ my: .5 }}>
                        <Typography fontSize={'1rem'}>Pregnant</Typography>
                        <RadioGroup orientation="horizontal" name="education" value={`pregnant-${currentPatient.pregnant || "no"}`} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPatient({ ...currentPatient, pregnant: e.target.value.split('-')[1] })} sx={{ mt: 1 }}>
                            <Radio label="Yes" value="pregnant-yes" size="sm" />
                            <Radio label="No" value="pregnant-no" size="sm" />
                        </RadioGroup>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2.5 }} />
            <Box sx={{ mx: 2 }}>
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'}>Conditions</Typography>
                    <Box sx={{ my: .5, width: '100%' }} >
                        <Autocomplete
                            size="sm"
                            value={currentPatient.current_medical_conditions}
                            placeholder={currentPatient.current_medical_conditions ? '' : 'None'}
                            multiple
                            freeSolo={true}
                            options={[]}
                            getOptionLabel={(option: string) => option}
                            filterSelectedOptions
                            onChange={(_, value) => setCurrentPatient({ ...currentPatient, current_medical_conditions: value })}
                        />
                    </Box>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'}>Medications</Typography>
                    <Box sx={{ my: .5, width: '100%' }} >
                        <Autocomplete
                            size="sm"
                            value={currentPatient.prescribed_medications || []}
                            placeholder={currentPatient.prescribed_medications ? '' : 'None'}
                            multiple
                            freeSolo={true}
                            options={[]}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(_, value) => setCurrentPatient({ ...currentPatient, prescribed_medications: value })}
                        />
                    </Box>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'}>Signs and Symptoms</Typography>
                    <Box sx={{ my: .5, width: '100%' }} >
                        <Autocomplete
                            size="sm"
                            value={currentPatient.clinical_symptoms || []}
                            placeholder={currentPatient.clinical_symptoms ? '' : 'None'}
                            multiple
                            freeSolo={true}
                            options={[]}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(_, value) => setCurrentPatient({ ...currentPatient, clinical_symptoms: value })}
                        />
                    </Box>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'}>Allergies</Typography>
                    <Box sx={{ my: .5, width: '100%' }} >
                        <Autocomplete
                            size="sm"
                            placeholder={currentPatient.allergies ? '' : 'Penicillin, Sulfa, etc…'}
                            multiple
                            freeSolo={true}
                            value={currentPatient.allergies || []}
                            options={[]}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(_, value) => setCurrentPatient({ ...currentPatient, allergies: value })}
                        />
                    </Box>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'}>Physical Exam</Typography>
                    <Box sx={{ my: .5, width: '100%' }} >
                        <Autocomplete
                            size="sm"
                            value={currentPatient.physical_exam_findings || []}
                            placeholder={currentPatient.physical_exam_findings ? '' : 'None'}
                            multiple
                            freeSolo={true}
                            options={[]}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(_, value) => setCurrentPatient({ ...currentPatient, physical_exam_findings: value })}
                        />
                    </Box>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'}>Lab Results</Typography>
                    <Box sx={{ my: .5, width: '100%' }} >
                        <Autocomplete
                            size="sm"
                            value={currentPatient.relevant_labs || []}
                            placeholder={currentPatient.relevant_labs ? '' : 'None'}
                            multiple
                            freeSolo={true}
                            options={[]}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(_, value) => setCurrentPatient({ ...currentPatient, relevant_labs: value })}
                        />
                    </Box>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'}>Additional Notes</Typography>
                    <Box sx={{ my: .5, width: '100%' }} >
                        <Textarea
                            size="sm"
                            minRows={3}
                            value={currentPatient.additional_notes}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCurrentPatient({ ...currentPatient, additional_notes: e.target.value })}
                        />
                    </Box>
                </Grid>
                <Divider sx={{ my: 2.5 }} />
                <Grid container spacing={2} sx={{ mt: .5 }}>
                    <Typography fontSize={'1rem'}>Social History</Typography>
                    <Box sx={{ my: .5, width: '100%' }} >
                        <Textarea
                            size="sm"
                            minRows={3}
                            value={currentPatient.social_hx}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCurrentPatient({ ...currentPatient, social_hx: e.target.value })}
                        />
                    </Box>
                </Grid>
            </Box>
        </>
    );
}



function ExtractionOptions({ setExtractionStep }: ExtractionOptionsProps): JSX.Element {

    const setStep = (step: string) => {
        return () => {
            setExtractionStep(step);
        }
    }

    return (
        <>
            <Button sx={{ backgroundColor: "#458795", mb: 1 }} onClick={setStep("audio")}>
                Audio Transcript
            </Button>
            <Button sx={{ backgroundColor: "#458795", mb: 1 }} onClick={setStep("file")}>
                File Upload
            </Button>
            {/* <Button sx={{ backgroundColor: "#458795", mb: 1 }} onClick={setStep("page")}>
                Page
            </Button> */}
            <Button sx={{ backgroundColor: "#458795", mb: 1 }} onClick={setStep("manual")}>
                Manual Entry
            </Button>
        </>
    );
}

async function uploadFile(file: File, setExtractionStep: React.Dispatch<React.SetStateAction<string>>): Promise<Patient> {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const disallowedExtensions = ['xlsm', 'xls'];

    // if (disallowedExtensions.includes(fileExtension)) {
    //     alert('Excel file types not allowed. Use a CSV file instead');
    //     setExtractionStep('initial')
    //     throw new Error('Excel file types not allowed. Use a CSV file instead');
    // }

    const url = `${BACKEND_ENDPOINT}/api/agents/patient-profile`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user", "test");

    try {
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });
        const responseText = await response.text();
        const responseJson = JSON.parse(responseText);
        const parsedPtProfile = responseJson.data;
        return parsedPtProfile;
    } catch (error) {
        alert('An error occurred when uploading the file. Please try again later');
        setExtractionStep('initial')
        return {} as Patient;
    }
}

const handleFileUpload = (setCurrentPatient: React.Dispatch<React.SetStateAction<Patient>>, setExtractionStep: React.Dispatch<React.SetStateAction<string>>): void => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.onchange = () => {
        const files = fileInput.files;
        if (files && files.length > 0) {
            uploadFile(files[0], setExtractionStep).then((parsedPtProfile) => {
                setCurrentPatient(parsedPtProfile);
            });
        }
    };
    fileInput.click();
};

interface ImmutablePatientProfileProps {
    currentPatient: Patient;
}

const ImmutablePatientProfile: React.FC<ImmutablePatientProfileProps> = ({ currentPatient }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ mb: .5 }}>
                        <Typography fontSize={'1rem'} fontWeight={600}>Date of Birth</Typography>
                        <Typography>{currentPatient.dob}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ mb: .5 }}>
                        <Typography fontSize={'1rem'} fontWeight={600}>Sex</Typography>
                        <Typography>{currentPatient.sex}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ my: .5 }}>
                        <Typography fontSize={'1rem'} fontWeight={600}>Weight</Typography>
                        <Typography>{currentPatient.weight}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ my: .5 }}>
                        <Typography fontSize={'1rem'} fontWeight={600}>Pregnant</Typography>
                        <Typography>{currentPatient.pregnant}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ mx: 2 }}>
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'} fontWeight={600}>Conditions</Typography>
                    <Box sx={{ my: .5, width: '100%' }}>
                        {currentPatient.current_medical_conditions.join(', ')}
                    </Box>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'} fontWeight={600}>Medications</Typography>
                    <Box sx={{ my: .5, width: '100%' }}>
                        {currentPatient.prescribed_medications.join(', ')}
                    </Box>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'} fontWeight={600}>Signs and Symptoms</Typography>
                    <Box sx={{ my: .5, width: '100%' }}>
                        {currentPatient.clinical_symptoms.join(', ')}
                    </Box>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'} fontWeight={600}>Allergies</Typography>
                    <Box sx={{ my: .5, width: '100%' }}>
                        {currentPatient.allergies.join(', ')}
                    </Box>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'} fontWeight={600}>Physical Exam</Typography>
                    <Box sx={{ my: .5, width: '100%' }}>
                        {currentPatient.physical_exam_findings.join(', ')}
                    </Box>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'} fontWeight={600}>Lab Results</Typography>
                    <Box sx={{ my: .5, width: '100%' }}>
                        {currentPatient.relevant_labs.join(', ')}
                    </Box>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} sx={{ my: .5 }}>
                    <Typography fontSize={'1rem'} fontWeight={600}>Additional Notes</Typography>
                    <Box sx={{ my: .5, width: '100%' }}>
                        {currentPatient.additional_notes}
                    </Box>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} sx={{ mt: .5 }}>
                    <Typography fontSize={'1rem'} fontWeight={600}>Social History</Typography>
                    <Box sx={{ my: .5, width: '100%' }}>
                        {currentPatient.social_hx}
                    </Box>
                </Grid>
            </Box>
        </>
    );
}

function PatientInputProcess({ currentPatient, setCurrentPatient, setCurrentApp, setIsPatientSelected, isPatientSelected }: { currentPatient: Patient, setCurrentPatient: React.Dispatch<React.SetStateAction<Patient>>, setCurrentApp: React.Dispatch<React.SetStateAction<string>>, setIsPatientSelected: React.Dispatch<React.SetStateAction<boolean>>, isPatientSelected: boolean }): JSX.Element {
    const [mediaRecorder, setMediaRecorder] = React.useState<MediaRecorder | null>(null);
    const [isRecording, setIsRecording] = React.useState<boolean>(false);
    const [audioLabel, setAudioLabel] = React.useState<string>('Start');
    const [extractionStep, setExtractionStep] = useState<string>("initial");

    const navigate = useNavigate();

    useEffect(() => {
        if (extractionStep === "file") {
            setExtractionStep("loading");
            handleFileUpload(setCurrentPatient, setExtractionStep);
        } else if (extractionStep === "manual") {
            setExtractionStep("done");
        }
    }, [extractionStep]);

    useEffect(() => {
        if (JSON.stringify(getEmptyPatient()) !== JSON.stringify(currentPatient)) setExtractionStep("done");
    }, [currentPatient]);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);

                const audioChunks: BlobPart[] = [];
                recorder.ondataavailable = event => {
                    audioChunks.push(event.data);
                };

                recorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                    const formData = new FormData();
                    formData.append('file', audioBlob, 'audio.wav');
                    fetch(`${BACKEND_ENDPOINT}/api/agents/voice-assistant`, {
                        method: 'POST',
                        body: formData,
                    }).then(response => response.json())
                        .then(data => {
                            if (data) {
                                setCurrentPatient(data.data);
                                setAudioLabel('Start');
                            }
                            stream.getTracks().forEach(track => track.stop());
                        })
                        .catch(error => console.error('Error transcribing audio:', error));
                };
            })
            .catch(err => console.error("Error accessing media devices:", err));
    }, []);

    const toggleRecording = (): void => {
        if (!mediaRecorder) return;

        if (isRecording) {
            mediaRecorder.stop();
            setIsRecording(false);
            setAudioLabel('Analyzing...');
        } else {
            try {
                mediaRecorder.start();
            } catch (e) {
                console.error('Failed to start MediaRecorder:', e);
            }
            setIsRecording(true);
            setAudioLabel('Stop');
        }
    };

    return (
        <>
            <List
                sx={{
                    overflowY: 'auto',
                    height: 'calc(100vh - 250px)',
                }}
            >
                {
                    extractionStep === "initial" && (
                        <ExtractionOptions setExtractionStep={setExtractionStep} />
                    )
                }
                {
                    extractionStep === "loading" && (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <CircularProgress size="lg" sx={{ mt:'25vh' }}/>
                            <Typography level="title-md" sx={{ mt: 2 }}>Loading</Typography>
                        </Box>
                    )
                }
                {
                    extractionStep === "audio" && (
                        <Button sx={{ backgroundColor: "#458795", mr: .5 }} onClick={toggleRecording} disabled={audioLabel === "Analyzing..."}>
                            {audioLabel}
                        </Button>
                    )
                }
                {
                    extractionStep === "done" && (
                        <>
                            <PatientProfile
                                currentPatient={currentPatient}
                                setCurrentPatient={setCurrentPatient}
                            />
                        </>
                    )
                }
                {
                    extractionStep === "profile" && (
                        <>
                            <ImmutablePatientProfile currentPatient={currentPatient} />
                        </>
                    )
                }
            </List>
            {
                (extractionStep !== "initial" && extractionStep !== "profile" ) && (
                    <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
                        <Button
                            onClick={() => setExtractionStep('initial')}
                            sx={{ backgroundColor: '#458795' }}
                        >
                            Back
                        </Button>
                        {
                            extractionStep == "done" && (
                                <Button
                                    onClick={() => {
                                        navigate('/assessment')
                                        setCurrentApp('assessment')
                                        setExtractionStep('profile')
                                        setIsPatientSelected(true)
                                    }}
                                    sx={{ backgroundColor: '#458795', ml: 1 }}
                                >
                                    Start Assessment
                                </Button>
                            )
                        }
                    </Box>
                )
            }
        </>
    );
}

export default function PatientInputApp({ currentPatient, setCurrentPatient, setCurrentApp, isPatientSelected, setIsPatientSelected }: { currentPatient: PatientProp, setCurrentPatient: React.Dispatch<React.SetStateAction<PatientProp>>, setCurrentApp: React.Dispatch<React.SetStateAction<string>>, isPatientSelected: boolean, setIsPatientSelected: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element {
    return (
        <>
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1, pb: 0 }}>
                <Grid container item sx={{ display: 'flex', height: '100%' }}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Typography fontSize={'2rem'} sx={{ pl: 2, pt: 2.5 }} >Patient Profile</Typography>
                    </Box>
                    <Box
                        sx={{
                            borderRadius: 5,
                            width: '100%',
                            px: 2,
                            pt: 1
                        }}
                    >   
                        { Object.keys(currentPatient).length === 0 && (
                            <Typography fontSize={'1rem'} sx={{ mb: 1}} >Please provide patient's information</Typography>
                        )}
                        <PatientInputProcess currentPatient={currentPatient} setCurrentPatient={setCurrentPatient} setCurrentApp={setCurrentApp} setIsPatientSelected={setIsPatientSelected} isPatientSelected={isPatientSelected} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}