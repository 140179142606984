import * as React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem'
import ListDivider from '@mui/joy/ListDivider';
import Snackbar, { SnackbarOrigin } from '@mui/joy/Snackbar';
import SearchIcon from '@mui/icons-material/Search';
import Drawer from '@mui/joy/Drawer';
import CloseIcon from '@mui/icons-material/Close';

import IntelligentSearchContent from './IntelligentSearchContent';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Divider } from '@mui/joy';
import CircularProgress from '@mui/joy/CircularProgress';
import useFirebaseUser from "../firebase/useFirebaseUser"

import { UserProp } from './Props';


interface State extends SnackbarOrigin {
  open: boolean;
}

export default function Header({ currentUser }: { currentUser: UserProp }) {

  const [intelligentSearchState, setIntelligentSearchState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [config, setConfig] = React.useState({
    query: '',
    documentsOnly: false,
    includeImages: false,
    stream: true,
  });

  const [prevConfig, setPrevConfig] = React.useState({
    query: '',
    documentsOnly: false,
    includeImages: false,
    stream: true,
  });

  const { vertical, horizontal, open } = intelligentSearchState;

  const [streamBody, setStreamBody] = React.useState('')
  const [streamReferences, setStreamReferences] = React.useState([])
  const [streamImages, setStreamImages] = React.useState([])
  const [streamQuestions, setStreamQuestions] = React.useState([])
  const [streamLoading, setStreamLoading] = React.useState(false)
  const [streamCitations, setStreamCitations] = React.useState([])
  const streamCitationsRef = React.useRef(streamCitations);
  const streamReferencesRef = React.useRef(streamReferences);
  
  const { mode, setMode } = useColorScheme();
  setMode('light');

  const { onLogout } = useFirebaseUser();

  const handleLogout = async (e: any) => {
    e.preventDefault();
    try {
      await onLogout();
      // You might want to add navigation logic here, e.g., redirect to login page
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleClose = () => {
    setIntelligentSearchState({ ...intelligentSearchState, open: false });
  };

  const requestIntelligentSearch = async (query: string) => {
    const endpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/agents/intelligent-search`
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "query": query,
        "stream": config.stream,
        "language": "en",
        "documentsOnly": config.documentsOnly,
      }),
    });
    const resJson = await response.json();
    return resJson;
  }

  const resetStreamResponse = () => {
    setStreamBody('');
    setStreamReferences([]);
    setStreamImages([]);
    setStreamQuestions([]);
    setStreamCitations([]);
  }

  const handleIntelligentSearchRequest = () => {
    if (config.query === '') {
      return
    }

    if (prevConfig === config) {
      setIntelligentSearchState({ ...intelligentSearchState, open: true, vertical: 'top', horizontal: 'center' });
      return
    }
    setStreamLoading(true);
    setPrevConfig(config);
    resetStreamResponse();
    requestIntelligentSearch(config.query).then((response) => {
      subscribeToSSE(response);
      setTimeout(() => {
        setIntelligentSearchState({ ...intelligentSearchState, open: true, vertical: 'top', horizontal: 'center' });
      }, 500);
    });
  };

  const toggleDocumentsOnly = () => {
    setConfig({ ...config, documentsOnly: !config.documentsOnly });
  }


  // NOTE: Inline citation logic here
  const buildCitationMap = (citation_id_brackat: string, newBody: string, citations: any[]) => {
    let citationMap = {id: 1, id_brackat: '', insert: '' }
    citationMap.id = parseInt(citation_id_brackat.replaceAll('[', '').replaceAll(']', ''));
    citationMap.id_brackat = citation_id_brackat;
    let targetCitation = citations?.find((citation: any) => citation.document_id === citationMap.id) as any;
    if (!targetCitation) {
      citationMap.insert = citationMap.id_brackat
      return newBody.replaceAll(citationMap.id_brackat, '');
    }
    citationMap.insert = `<citationData>{"url":"${targetCitation.url}","id":${targetCitation.source_id},"source":"${targetCitation.source}"}</citationData>`;
    newBody = newBody.replaceAll(citationMap.id_brackat, citationMap.insert);
    return newBody
  }

  const buildInlineCitations = (newBody: string, citations: any[]) => {
    const regex = /\[(\d+)\]/g;
    let match;
    while ((match = regex.exec(newBody)) !== null) {
      newBody = buildCitationMap(match[0], newBody, citations);
    }
    return newBody;
  };

  React.useEffect(() => {
    streamCitationsRef.current = streamCitations;
    streamReferencesRef.current = streamReferences;
  }, [streamCitations, streamReferences]);
  // END

  const subscribeToSSE = async (streamId: string) => {
    const SERVER_ENDPOINT = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/agents/intelligent-search/stream/${streamId}`

    const newSource = new EventSource(SERVER_ENDPOINT, {
      withCredentials: false
    });

    
    newSource.addEventListener('citations', (event) => {
      try {
        if (streamId === event.lastEventId) {
            setStreamCitations(JSON.parse(event.data));
        }
      } catch (err) {
        //console.log(event.data);
      }
    });

    newSource.addEventListener('newToken', (event) => {
      try {
        if (streamId === event.lastEventId) {
          let citations = [...streamCitationsRef.current];
          setStreamBody((prevStreamBody) => (buildInlineCitations(prevStreamBody, citations) + event.data.replace(/\\n/g, '\n').replace(/\"/g, '')));
        }
      } catch (err) {
        //console.log(err);
      }
    });

    newSource.addEventListener('documentsInfo', (event) => {
      try {
        if (streamId === event.lastEventId) {
          setStreamReferences(JSON.parse(event.data));
        }
      } catch (err) {
        //console.log(event.data);
      }
    });



    newSource.addEventListener('relatedImages', (event) => {
      try {
        if (streamId === event.lastEventId) {
          setStreamImages(JSON.parse(event.data));
        }
      } catch (err) {
        //console.log(event.data);
      }
    });

    newSource.addEventListener('done', (event) => {
      if (streamId === event.lastEventId) {
        newSource.close();
        setStreamLoading(false);
        setIntelligentSearchState({ ...intelligentSearchState, open: true, vertical: 'top', horizontal: 'center' });
      }
    });

  }

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <IconButton
          size="md"
          variant="outlined"
          color="neutral"
          sx={{
            display: { xs: 'none', sm: 'inline-flex' },
            borderRadius: '50%',
          }}
        >
          <a href="https://www.asepha.ai" target="_blank" rel="noopener noreferrer">
            <img
              src="/logo_icon.png" alt="logo"
              style={{ width: '25px', height: '25px' }}
            />
          </a>
        </IconButton>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center', // Center the contents vertically
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
              src="/asepha_studio_logo.png" alt="logo"
              style={{height: '50px' }}
            />
        </Box>
        <IconButton
          onClick={toggleDrawer}
          size="sm"
          variant="outlined"
          color="neutral"
          sx={{ mr: 1 }}
        >
          <SearchIcon />
        </IconButton>
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
          >
            <Avatar
              color='primary'
              sx={{ maxWidth: '32px', maxHeight: '32px' }}
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 2,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem
              component="a"
              href="https://asepha.ai"
            >
              Product Help
              <OpenInNewRoundedIcon />
            </MenuItem>
            <ListDivider />
            <MenuItem onClick={handleLogout}>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>

      <Drawer
        size="sm"
        variant="plain"
        open={drawerOpen}
        onClose={toggleDrawer}
        anchor="left"
        sx={{
          '& .MuiDrawer-content': {
            width: '30%',
            minWidth: '450px',
            bgcolor: 'background.surface',
            p: 0, // Remove padding
          },
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
          <IconButton onClick={toggleDrawer} variant="plain" color="neutral">
            <CloseIcon />
          </IconButton>
        </Box>
        <iframe
          src="https://dev.search.glycogen-ai.com"
          style={{
            width: '100%',
            height: '100%', // Take up the entire drawer
            border: 'none',
          }}
          title="Embedded Content"
        />
      </Drawer>
    </Box>
  );
}