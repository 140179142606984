import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/joy';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import HandoutMain from './TextEditor';
import ReactQuill from 'react-quill';
import Skeleton from '@mui/joy/Skeleton';
import { Alert, IconButton, Button, Textarea, Stack } from '@mui/joy';
import ReportIcon from '@mui/icons-material/Report';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';
import Badge from '@mui/joy/Badge';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/joy/Modal';
import { styled } from '@mui/joy/styles';
import { keyframes } from '@mui/system';
import { Add } from '@mui/icons-material';
import { ModalDialog, ModalClose } from '@mui/joy';

interface SubsectionContent {
  title: string;
  explanation: string;
}

interface SectionContent {
  title: string;
  subsections: SubsectionContent[];
}

interface DocumentationContent {
  title: string;
  content: string;
}

const SUBSECTION_TITLE_TO_LABEL: { [key: string]: string } = {
  'Indication/Use': 'indication',
  'Dosage': 'dosage',
  'Safety': 'safety',
  'Condition Control': 'conditionControl',
  'Alternative Therapy': 'alternativeTherapy',
  'Unmet Needs': 'unmetNeeds',
  'Drug Interactions': 'drugInteractions',
  'Clinical Summary': 'clinicalSummary',
  'Patient Education': 'patientEducationSummary',
};

function dtpBubble({ dtpObjects, setDtpObjects, index }: { dtpObjects: any, setDtpObjects: any, index: number }) {
  var reasoning = '';
  var title = ''
  var bubbleState = '';
  var textState = '';
  try {
      title = dtpObjects[index].dtp_type;
      reasoning = dtpObjects[index].reasoning;
      bubbleState = dtpObjects[index].bubbleState;
      textState = dtpObjects[index].textState;
  }
  catch (e) {
      console.error('Error parsing DTP statement', e);
  }
  if (bubbleState === 'declined') {
      return (
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', ml: 1.5 }}>
              <Alert
                  key={title}
                  sx={{ alignItems: 'flex-start' }}
                  startDecorator={<ReportIcon />}
                  endDecorator={
                      <IconButton variant="soft">
                          <ReplayIcon onClick={() => {
                              setDtpObjects(dtpObjects.map((obj: any, i: any) => {
                                  if (i === index) {
                                      obj.bubbleState = 'pending';
                                      return obj;
                                  }
                                  return obj;
                              }
                              ));
                          }} />
                      </IconButton>
                  }
              >
                  <div>
                      <Typography>{title}</Typography>
                  </div>
              </Alert>
          </Box>
      )
  } else if (bubbleState === 'edit') {
      // update the reasoning and title
      return (
          <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', ml: 1.5 }}>
              <Alert
                  key={title}
                  sx={{ alignItems: 'flex-start' }}
                  startDecorator={<ReportIcon />}
                  variant="soft"
                  color="warning"
                  endDecorator={
                      <IconButton variant="soft" color={'warning'}>
                          <CheckIcon onClick={() => {
                              setDtpObjects(dtpObjects.map((obj: any, i: any) => {
                                  if (i === index) {
                                      obj.bubbleState = "pending";
                                      return obj;
                                  }
                                  return obj;
                              }));
                          }} />
                      </IconButton>
                  }
              >
                  <Box sx={{ width: '100%' }}>
                      <Textarea defaultValue={title} sx={{ mb: 1 }} size="sm" onChange={(e) => {
                          setDtpObjects(dtpObjects.map((obj: any, i: any) => {
                              if (i === index) {
                                  obj.dtp_type = e.target.value;
                                  return obj;
                              }
                              return obj;
                          }));
                      }
                      } />
                      <Textarea defaultValue={reasoning} size="sm" onChange={(e) => {
                          setDtpObjects(dtpObjects.map((obj: any, i: any) => {
                              if (i === index) {
                                  obj.reasoning = e.target.value;
                                  return obj;
                              }
                              return obj;
                          }));
                      }
                      } />
                  </Box>
              </Alert>
          </Box>
      )
  }
  return (
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', ml: 1.5 }}>
          <Alert
              key={title}
              sx={{ alignItems: 'flex-start' }}
              startDecorator={<ReportIcon />}
              variant="soft"
              color="warning"
              endDecorator={
                  <IconButton variant="soft" color={'warning'}>
                      <EditNoteIcon onClick={() => {
                          setDtpObjects(dtpObjects.map((obj: any, i: any) => {
                              if (i === index) {
                                  obj.bubbleState = "edit";
                                  return obj;
                              }
                              return obj;
                          }));
                      }} />
                  </IconButton>
              }
          >
              <div>
                  <Typography >{title}</Typography>
                  <Typography level="body-xs" >
                      {reasoning}
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      <Button size="sm"
                          variant={bubbleState === "approved" ? "solid" : "outlined"}
                          color={bubbleState === "approved" ? "success" : "danger"}
                          onClick={() => {
                              setDtpObjects(dtpObjects.map((obj: any, i: any) => {
                                  if (i === index) {
                                      if (obj.bubbleState === "approved") {
                                          obj.bubbleState = "pending";
                                          return obj;
                                      }
                                      obj.bubbleState = "approved";
                                      return obj;
                                  }
                                  return obj;
                              }));
                          }
                          }>
                          {bubbleState === "approved" ? "Approved" : "Approve"}
                      </Button>
                      <Button size="sm"
                          variant="outlined"
                          color="danger"
                          onClick={() => {
                              setDtpObjects(dtpObjects.map((obj: any, i: any) => {
                                  if (i === index) {
                                      obj.bubbleState = "declined";
                                      return obj;
                                  }
                                  return obj;
                              }));
                          }
                          }>
                          Decline
                      </Button>
                  </Stack>
              </div>
          </Alert>
      </Box>
  )
}

const AddDtpBubble: React.FC<{ dtpObjects: any, setDtpObjects: any }> = ({ dtpObjects, setDtpObjects }) => {
  const [open, setOpen] = useState(false);
  const [newDtpType, setNewDtpType] = useState('');
  const [newReasoning, setNewReasoning] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAdd = () => {
    const newDtp = {
      dtp_type: newDtpType,
      reasoning: newReasoning,
      bubbleState: 'pending' as const,
      textState: 'pending' as const
    };
    setDtpObjects([...dtpObjects, newDtp]);
    handleClose();
    setNewDtpType('');
    setNewReasoning('');
  };

  return (
    <>
      <Button
        startDecorator={<AddIcon />}
        onClick={handleOpen}
        sx={{ m: 1.5, mb: 0 }}
        color='warning'
      >
      Add New DTP
      </Button>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog
          aria-labelledby="add-dtp-modal-title"
          aria-describedby="add-dtp-modal-description"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              bgcolor: 'background.body',
            }}
          />
          <Typography
            component="h2"
            id="add-dtp-modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Add New DTP
          </Typography>
          <Typography id="add-dtp-modal-description" textColor="text.tertiary">
            Fill in the details for the new Drug Therapy Problem.
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleAdd();
            }}
          >
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Textarea
                placeholder="DTP Type"
                value={newDtpType}
                onChange={(e) => setNewDtpType(e.target.value)}
                minRows={2}
              />
              <Textarea
                placeholder="Reasoning"
                value={newReasoning}
                onChange={(e) => setNewReasoning(e.target.value)}
                minRows={3}
              />
              <Button type="submit">Add DTP</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
};

const flickerAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiAccordionSummary-indicator': {
    color: 'green',
  },
  '&.Mui-expanded .MuiAccordionSummary-indicator': {
    color: theme.vars.palette.text.tertiary,
  },
  '&.user-unopened .MuiAccordionSummary-indicator': {
    animation: `${flickerAnimation} 1s ease-in-out infinite`,
  },
}));

function MainContentArea({ currentPatient, indicationsUse, dosage, safety, conditionControl, alternativeTherapy, unmetNeeds, drugInteractions, isPatientSelected, expandedSections, setExpandedSections, drugsEvidence, clinicalSummary, patientEducationSummary, dtpBubbleContents, setDtpBubbleContents, taskId, setDocumentationTaskId }: { currentPatient: any, indicationsUse: string, dosage: string, safety: string, conditionControl: string, alternativeTherapy: string, unmetNeeds: string, drugInteractions: string, isPatientSelected: boolean, expandedSections: { [key: string]: boolean }, setExpandedSections: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>, drugsEvidence: any, clinicalSummary: string, patientEducationSummary: string, dtpBubbleContents: any, setDtpBubbleContents: any, taskId: string, setDocumentationTaskId: any }) {
  const assessmentSections: SectionContent[] = [
    {
      title: 'Medications Overview',
      subsections: [
        {
          title: 'Indication/Use',
          explanation: indicationsUse || "Analysis has not started. This might take a few seconds.",
        },
        {
          title: 'Dosage',
          explanation: dosage || "Analysis has not started. This might take a few seconds.",
        },
        {
          title: 'Safety',
          explanation: safety || "Analysis has not started. This might take a few seconds.",
        },
      ],
    },
    {
      title: 'Conditions Overview',
      subsections: [
        {
          title: 'Condition Control',
          explanation: conditionControl || "Analysis has not started. This might take a few seconds.",
        },
        {
          title: 'Alternative Therapy',
          explanation: alternativeTherapy || "Analysis has not started. This might take a few seconds.",
        },
        {
          title: 'Unmet Needs',
          explanation: unmetNeeds || "Analysis has not started. This might take a few seconds.",
        },
      ],
    },
    {
      title: 'Drug Interactions',
      subsections: [
        {
          title: 'Drug Interactions',
          explanation: drugInteractions || "Analysis has not started. This might take a few seconds.",
        },
      ],
    },
  ];
  const documentationSections: DocumentationContent[] = [
    {
      title: 'Clinical Summary',
      content: clinicalSummary || "Documentation has not started. This might take a few seconds.",
    },
    {
      title: 'Patient Education',
      content: patientEducationSummary || "Documentation has not started. This might take a few seconds.",
    }
  ];

  const [dtpObjects, setDtpObjects] = useState([]);

  const [assessmentOpen, setAssessmentOpen] = React.useState(false);
  const [documentationOpen, setDocumentationOpen] = React.useState(false);
  const [dtpsOpen, setDtpsOpen] = React.useState(false);
  const [dtpsApproved, setDtpsApproved] = React.useState(false);

  const handleAssessmentOpen = () => {
    console.log('assessmentOpen will be changed to', !assessmentOpen);
    setAssessmentOpen(!assessmentOpen);
  };

  const handleDocumentationOpen = () => {
    setDocumentationOpen(!documentationOpen);
  }

  const handleDtpsOpen = () => {
    setDtpsOpen(!dtpsOpen);
  }

  const handleDocumentationRequest = async (taskId:any) => {
      setDtpsApproved(true);
      const approvedDtps = dtpObjects.filter((dtp: any) => dtp.bubbleState === 'approved');
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/agents/dtp/v3/summaries`, {
          dtpObjects: approvedDtps,
          patient: currentPatient,
          stream: true,
          taskId,
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setDtpsOpen(false);
        setAssessmentOpen(false);
        setDocumentationOpen(true);
        setExpandedSections(prevSections => ({
          ...prevSections,
          'clinical summary-clinicalSummary': true
        }));
        return response.data;
      } catch (error) {
        console.error('Error in documentation request:', error);
        return "Error";
      }
    }

  useEffect(() => {
    if (Object.values(expandedSections).some(Boolean)) {
      if (expandedSections['clinical summary-clinicalSummary'] || expandedSections['patient education-patientEducationSummary']) {
        setDocumentationOpen(true);
      }
      else if (Object.keys(expandedSections).some(key => key !== 'clinical summary-clinicalSummary' && key !== 'patient education-patientEducationSummary' && expandedSections[key] && key.includes('-'))) {
        setAssessmentOpen(true);
      }
    }
  }, [expandedSections]);

  useEffect(() => {
    if (dtpBubbleContents && dtpBubbleContents.identifiedDTPs) {
      const newDtpObjects = dtpBubbleContents.identifiedDTPs.map((dtp: {
        dtp_type: string;
        drug_name_and_dose: string;
        reasoning: string;
      }) => ({
        dtp_type: `${dtp.dtp_type} - ${dtp.drug_name_and_dose}`,
        reasoning: dtp.reasoning,
        bubbleState: 'pending' as const,
        textState: 'pending' as const
      }));
      setDtpObjects(newDtpObjects);
    }
  }, [dtpBubbleContents]);

  const [openedSections, setOpenedSections] = useState<{ [key: string]: boolean }>({});

  const handleExpansion = (section: string, subsection?: string) => {
    const key = `${section}-${subsection}`;
    setExpandedSections(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
    if (!openedSections[key]) {
      setOpenedSections(prev => ({
        ...prev,
        [key]: true,
      }));
    }
  };

  const isEvidenceEmpty = () => {
    return Object.keys(drugsEvidence).length < 1;
  };

  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2, pt: .7, width: '100%', maxWidth: '100%' }}>
      {/* <Typography fontSize={'2rem'}>Asepha's Workspace</Typography> */}
      <AccordionGroup sx={{ pt: 1 }}>
        <Accordion disabled={isEvidenceEmpty()} expanded={!isEvidenceEmpty() && assessmentOpen} onChange={handleAssessmentOpen}>
          <AccordionSummary>
            <Typography fontSize={'1.5rem'} ><Skeleton loading={isEvidenceEmpty()} animation={isPatientSelected ? 'wave' : false}>Assessment Details</Skeleton></Typography>
          </AccordionSummary>
          <AccordionDetails>
            {assessmentSections.map((section, mainIndex) => (
              <>
                {section.subsections.map((subsection, subIndex) => {
                  const key = `${section.title.toLowerCase()}-${SUBSECTION_TITLE_TO_LABEL[subsection.title]}`;
                  return (
                    <StyledAccordion
                      key={subIndex}
                      expanded={expandedSections[key] || false}
                      onChange={() => handleExpansion(section.title.toLowerCase(), SUBSECTION_TITLE_TO_LABEL[subsection.title])}
                      className={openedSections[key] ? 'opened' : 'user-unopened'}
                    >
                      <AccordionSummary
                        indicator={<ExpandMoreIcon />}
                      >
                        <Typography fontSize={'1rem'}>{subsection.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ backgroundColor: '#f5f5f5', p: 2 }}>
                          <ReactMarkdown
                            children={subsection.explanation}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                              code: ({ node, className, children, ...props }) => (
                                <span
                                  style={{
                                    backgroundColor: '#fdfd96',
                                    padding: '0 3px',
                                    display: 'inline-block',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-all',
                                    whiteSpace: 'normal',
                                  }}
                                  {...props}
                                >
                                  {children}
                                </span>
                              ),
                            }}
                          />
                        </Box>
                      </AccordionDetails>
                    </StyledAccordion>
                  );
                })}
              </>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion disabled={ dtpBubbleContents.length < 1 } expanded={!isEvidenceEmpty() && dtpsOpen} onChange={handleDtpsOpen}>
          <AccordionSummary>
            <Typography fontSize={'1.5rem'} >
              <Skeleton loading={isEvidenceEmpty()} animation={isPatientSelected ? 'wave' : false}>
              Potential Drug Therapy Problems
              {dtpObjects.length > 0 && (
                <Badge sx={{ ml: 1, mb: 3 }} badgeContent={dtpObjects.length} color="warning" />
              )}
              </Skeleton>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', mt: 1 }}>
              {dtpObjects.map((dtp: any, index: any) => (
                dtpBubble({ dtpObjects, setDtpObjects, index })
              ))}
            </Box>

            { AddDtpBubble({ dtpObjects, setDtpObjects }) }

            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', m: 1.5 }}>
              <Button
                key="acknowledge"
                variant="solid"
                color="primary"
                onClick={() => handleDocumentationRequest(taskId)}
                disabled={dtpsApproved || dtpObjects.filter((dtp: any) => dtp.bubbleState === 'approved').length < 1}
              >
                Create Documentation
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion disabled={!dtpsApproved} expanded={!isEvidenceEmpty() && documentationOpen} onChange={handleDocumentationOpen}>
          <AccordionSummary>
            <Typography fontSize={'1.5rem'} ><Skeleton loading={!isPatientSelected || isEvidenceEmpty()} animation={isPatientSelected ? 'wave' : false}>Documentation</Skeleton></Typography>
          </AccordionSummary>
          <AccordionDetails>
            {documentationSections.map((doc, docIndex) => (
              <Accordion key={docIndex}
                expanded={expandedSections[`${doc.title.toLowerCase()}-${SUBSECTION_TITLE_TO_LABEL[doc.title]}`] || false}
                onChange={() => handleExpansion(doc.title.toLowerCase(), SUBSECTION_TITLE_TO_LABEL[doc.title])}
              >
                <AccordionSummary>
                  <Typography fontSize={'1rem'}>{doc.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ backgroundColor: '#f5f5f5', p: 2 }}>
                    <ReactMarkdown
                      children={doc.content}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        code: ({ node, className, children, ...props }) => (
                          <span
                            style={{
                              backgroundColor: '#fdfd96',
                              padding: '0 3px',
                              display: 'inline-block',
                              wordWrap: 'break-word',
                              wordBreak: 'break-all',
                              whiteSpace: 'normal',
                            }}
                            {...props}
                          >
                            {children}
                          </span>
                        ),
                      }}
                    />


                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </Box>
  );
};

export default MainContentArea;
